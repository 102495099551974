import React from 'react'
import { Link, graphql } from 'gatsby'

import { rhythm, scale } from '../utils/typography'

import Bio from '../components/Bio'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import Share from '../components/Share'

// Zoom image system, necessary modules (all of them?)
// Styled for styling css
import styled from 'styled-components'
// rehype-react to embed components in Markdown
import rehypeReact from 'rehype-react'
// a "simple" convenience wrapper
import ZoomImage from '../components/ZoomImage'
// Component to hide code
import Hidden from '../components/Hidden'

// Here's some stuff required
// Custom components are created that can be used in markdown files, to inline html code.
// Components are imported in this file, then "aliases" are created for the component names in lower case...
// These can then be called with <hidden> etc. tags. Any other react component can be inlined this way.
const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    'zoom-image': ZoomImage,
    'hidden': Hidden,
  }
}).Compiler;

// see these:
// https://www.gatsbyjs.org/packages/gatsby-remark-component/
// https://prestonrichey.com/blog/react-in-markdown/

// replaced for this to work:
// <div dangerouslySetInnerHTML={{ __html: post.html }} />
// with
// <div>{renderAst(post.htmlAst)}</div>

// In query:
// replaced
// html with htmlAst

/*
const StyledTextBody = styled.div`
  p {
    line-height: 1.5em;
  }
`;
*/

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext
    const twitterHandle = this.props.data.site.siteMetadata.twitterHandle
    const url = this.props.data.site.siteMetadata.url
    const slug = post.fields.slug
    const tags = post.frontmatter.tags
    const title = post.frontmatter.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={post.frontmatter.title} description={post.excerpt} />

        <h1>{post.frontmatter.title}</h1>
        <p
          style={{
            ...scale(-1 / 5),
            display: `block`,
            marginBottom: rhythm(1),
            marginTop: rhythm(-1),
          }}
        >
          {post.frontmatter.date}
        </p>

          <h4 style={{ marginBottom: `10px` }}>
            Tags: {tags.map((aTag, i) => <Link to={'/tags/' + aTag} key={i}> {aTag} </Link>)}
          </h4>

        <div>{renderAst(post.htmlAst)}</div>

        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />
        <Bio />

        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>

        <Share
				socialConfig={{
					twitterHandle,
					config: {
						url: `${url}${slug}`,
						title,
					},
				}}
			  />

      </Layout>
    )
  }
}

export default BlogPostTemplate

// We pull in the data in markdowns using graphQL.
// We need to add queries to get social stuff here...
export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
        url
        twitterHandle
      }
    }
    markdownRemark(
      fields: { slug: { eq: $slug } }
      ) {
      id
      excerpt(pruneLength: 160)
      htmlAst # previously "html"
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        tags
      }
      fields {
        slug
      }
    }
  }
`
