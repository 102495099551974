import React from 'react'
import ImageZoom from 'react-medium-image-zoom'
import styled from 'styled-components'

// Using styled to style figcaption, which is a default html element, used to add text to figures (images used in text)
const StyledFigcaption = styled.figcaption`
  text-align: left;
  font-size: 0.85em;
  padding-bottom: 20px;
  // text-transform: uppercase;
  color: #999;
`

// This belongs to medium-style zoom image setup.
// Based on concept by Preston Richey, how to implement React components in Gatsby markdown pages.
// https://prestonrichey.com/blog/react-in-markdown/

class ZoomImage extends React.Component {
  render() {
    return (
      <figure>
        <ImageZoom
          image={{
            src: this.props.src,
            alt: this.props.alt || this.props.caption,
            style: { width: '80%' }
          }}
          zoomImage={{
            src: this.props.zoomsrc || this.props.src,
            alt: this.props.alt || this.props.caption,
          }}
          defaultStyles={{
            overlay: { backgroundColor: '#2c2c30' }
          }}
        />

        {this.props.caption && (
          <StyledFigcaption>{this.props.caption}</StyledFigcaption>
        )}
      </figure>
    );
  }
}

export default ZoomImage