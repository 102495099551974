import React from 'react';
import PropTypes from 'prop-types';

// Import font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import your fontawesome library
import Fontawesome from '../components/Fontawesome';


// This was cobbled together from this:
// https://swas.io/blog/social-share-button-for-gatsby-blog-pages/
// Import react-share
import {
	FacebookShareButton,
	TwitterShareButton,
	WhatsappShareButton,
	RedditShareButton,
	EmailShareButton
} from 'react-share';

import './Share.scss';

const Share = ({ socialConfig, tags }) => (
	<div className="post-social" style={{ display: `flex`, flexWrap: `wrap` }} >
		<FacebookShareButton url={socialConfig.config.url} quote={socialConfig.config.title} className="button facebook" >
			<span className="icon">
				<FontAwesomeIcon icon={['fab', 'facebook-f']} />
			</span>
			<span className="text">Facebook</span>
		</FacebookShareButton>
		<TwitterShareButton url={socialConfig.config.url} className="button twitter" title={socialConfig.config.title} via={socialConfig.twitterHandle.split('@').join('')} hashtags={tags} >
			<span className="icon">
				<FontAwesomeIcon icon={['fab', 'twitter']} />
			</span>
			<span className="text">Twitter</span>
		</TwitterShareButton>
		<WhatsappShareButton url={socialConfig.config.url} className="button whatsapp" title={socialConfig.config.title} >
			<span className="icon">
				<FontAwesomeIcon icon={['fab', 'whatsapp']} />
			</span>
			<span className="text">WhatsApp</span>
		</WhatsappShareButton>
		<RedditShareButton url={socialConfig.config.url} className="button reddit" title={socialConfig.config.title} >
			<span className="icon">
				<FontAwesomeIcon icon={['fab', 'reddit-alien']} />
			</span>
			<span className="text">Reddit</span>
		</RedditShareButton>
		<EmailShareButton subject={socialConfig.config.title} className="button email" body={socialConfig.config.url} >
			<span className="icon">
				<FontAwesomeIcon icon={['fas', 'envelope-square']} />
			</span>
			<span className="text">E-mail</span>
		</EmailShareButton>
	</div>
);


// socialConfig: An object of the mentioned shape to pass in our configuration data.
// We pass the common url and title inside config hash and a separate twitterHandle for twitter specific share.

// tags: Optional array of tags of the blog post.
// This is implementation specific. If you are not using any tags, then you can just leave it as is.
Share.propTypes = {
	socialConfig: PropTypes.shape({
		twitterHandle: PropTypes.string.isRequired,
		config: PropTypes.shape({
			url: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
		}),
	}).isRequired,
	tags: PropTypes.arrayOf(PropTypes.string),
};
Share.defaultProps = {
	tags: [],
};

export default Share;