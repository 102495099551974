import React from 'react'

class Hidden extends React.Component {

  // returns nothing. Neat. But does it work?
  render() {
    return null;
  }

}

export default Hidden